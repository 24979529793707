<template>
  <div>
    <vue-headful :title="pageTitle"></vue-headful>
    <div class="row heading">
      <div class="col-md-3">
        <BusinessEntitySideBar />
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <button class="nav-link btn btn-link" v-bind:class="isInfo" @click="handleInfoClick">Info</button>
              </li>
              <li class="nav-item">
                <button class="nav-link btn btn-link" v-bind:class="isDocuments" @click="handleDocumentsClick">Documents</button>
              </li>
              <li class="nav-item" v-if="!isUnisearch">
                <button class="nav-link btn btn-link" v-bind:class="isWebServices" @click="handleWebServicesClick">Web Services</button>
              </li>
              <li class="nav-item" v-if="!isUnisearch">
                <button class="nav-link btn btn-link" v-bind:class="isDbas" @click="handleDbasClick">DBAs</button>
              </li>
              <li class="nav-item" v-if="!isUnisearch">
                <button class="nav-link btn btn-link" v-bind:class="isChangelog" @click="handleChangelogClick">Changelog</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BusinessEntitySideBar from './business-entity-side-bar';
import filestackClient from '../../client/filestack';
import { hasPermissionMixin } from '@/components/mixins';

export default {
  name: 'BusinessEntity',

  components: {
    BusinessEntitySideBar
  },

  mixins: [hasPermissionMixin],

  props: ['businessEntityUuid'],

  data() {

    const active_route = this.$route.path.split('/').at(-1)

    return {
      view: active_route ? active_route : 'info'
    };
  },

  computed: {
    ...mapState({
      businessEntities: (state) => state.businessEntities.businessEntities,
      businessEntity: (state) => state.businessEntities.businessEntity,
      jurisdictions: (state) => state.businessEntities.jurisdictions,
      addresses: (state) => state.businessEntities.addresses
    }),
    isInfo() {
      return {
        active: this.view === 'info'
      };
    },
    isDocuments() {
      return {
        active: this.view === 'documents'
      };
    },
    isWebServices() {
      return {
        active: this.view === 'webservices'
      };
    },
    isDbas() {
      return {
        active: this.view === 'dbas'
      };
    },
    isChangelog() {
      return {
        active: this.view === 'changelog'
      };
    },
    pageTitle() {
      return `Entity - ${this.businessEntity.business_entity_name || this.businessEntity.business_entity_uuid || 'Unavailable'}`;
    },
    isUnisearch() {
      return this.hasPermission('partner:unisearch');
    }
  },

  methods: {
    ...mapActions(
      {
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity',
    }
    ),
    openFilePicker() {
      const options = {
        maxFiles: 1,
        uploadInBackground: false,
        displayMode: 'overlay',
        onUploadDone: (res) => this.updateFile(res)
      };
      filestackClient.getClient().then((client) => {
        const picker = client.picker(options);
        picker.open();
      });
    },
    handleInfoClick() {
      if (this.$router.history.current.name !== 'BusinessEntityInfo') {
        this.view = 'info';
        this.$router.push({ name: 'BusinessEntityInfo', params: { businessEntityUuid: this.businessEntityUuid } });
      }
    },
    handleDocumentsClick() {
      if (this.$router.history.current.name !== 'BusinessEntityDocuments') {
        this.view = 'documents';
        this.$router.push({ name: 'BusinessEntityDocuments', params: { businessEntityUuid: this.businessEntityUuid } });
      }
    },
    handleWebServicesClick() {
      if (this.$router.history.current.name !== 'BusinessEntityWebServices') {
        this.view = 'webservices';
        this.$router.push({ name: 'BusinessEntityWebServices', params: { businessEntityUuid: this.businessEntityUuid, websiteURL: this.businessEntity.business_entity_website_url } });
      }
    },
    handleDbasClick() {
      if (this.$router.history.current.name !== 'BusinessEntityDbas') {
        this.view = 'dbas';
        this.$router.push({ name: 'BusinessEntityDbas', params: { businessEntityUuid: this.businessEntityUuid } });
      }
    },
    handleChangelogClick() {
      if (this.$router.history.current.name !== 'BusinessEntityChangelog') {
        this.view = 'changelog';
        this.$router.push({ name: 'BusinessEntityChangelog', params: { businessEntityUuid: this.businessEntityUuid } });
      }
    }
  },

  created() {
    this.fetchBusinessEntity({ uuid: this.businessEntityUuid });
  }

};
</script>

<style lang="scss" scoped>

</style>
